<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <!-- <pre>{{ form }}</pre> -->
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <!-- <CCol sm="6">
          <CInput v-model="form[`name`]" :label="`Name`" :placeholder="`Name`" />
        </CCol>

        <CCol sm="6"></CCol> -->

        <CCol sm="6" v-for="lang in languages" :key="`name_${lang}`">
          <CInput
            v-model="form[`name_${lang}`]"
            :label="`Name  ${lang}`"
            :placeholder="`Name ${lang}`"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6" v-for="lang in languages" :key="`description_${lang}`">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6" v-for="lang in languages" :key="`help_${lang}`">
          <CInput
            v-model="form[`help_${lang}`]"
            :label="`Help  ${lang}`"
            :placeholder="`Help ${lang}`"
          />
        </CCol>
      </CRow>

      <!-- <CRow v-for="lang in languages" :key="`description_${lang}`">
        <CCol sm="12">
          <CInput
            v-model="form[`description_${lang}`]"
            :label="`Description  ${lang}`"
            :placeholder="`Description ${lang}`"
          />
        </CCol>
      </CRow>-->

      <CRow>
        <CCol sm="12">
          <CTextarea v-model="form.prompt" label="Prompt" placeholder="Prompt" rows="5" />
        </CCol>
      </CRow>

      <CRow>
        <!-- <CCol sm="6">
          <AppSlect v-model="form.tag" :options="tagOptions" label="Tag" />
        </CCol> -->

        <CCol sm="6">
          <MultiselectServerSearch
            v-model="form.tags"
            label="Tags"
            placeholder="Tags"
            :data="tagOptions"
            :multiple="true"
          />
        </CCol>

        <CCol sm="6">
          <CInput
            v-model="form.count_open"
            label="Count open"
            placeholder="Count open"
          />
        </CCol>
      </CRow>

      <CRow>
        <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.order" label="Order" placeholder="Order" />
        </CCol>

        <!-- <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.image && form.image.file"
            name="image"
            :allowDelete="false"
            :image="form.image && form.image.url ? form.image.url : form.image"
            @input="value => handleChangeFile({ value, key: 'image' })"
          />
        </CCol> -->

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>

        <CCol sm="2">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Free"
            :checked="form.free"
            @update:checked="value => (form.free = value)"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import MultiselectServerSearch from "@/components/root/MultiselectServerSearch";

// import ModalPresets from "./ModalPresets";

// import FileLoader from "@/components/root/FileLoader";
// import FileLoaderUrl from "@/components/root/FileLoaderUrl";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    MultiselectServerSearch,
  },

  data: () => ({
    form: {
      prompt: null,
      count_open: 0,
      show: true,
      free: false,
      created_at: null,
      tags: [],
      order: 0
    },

    languages: ["en", "ru", "fr", "es", "de", "it"],

    // types: ["9/16", "1/1", "4/3", "3/4", "16/9"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data
          ? "Edit Powerpoint AI Prompt"
          : "Add Powerpoint AI Prompt";
      }
    },

    createdAt() {
      // return this.form[`created_at`];
      // return moment(this.form[`created_at`])
      //   .subtract("hours", 3)
      //   .format("YYYY-MM-DD hh:mm:ss");
    },

    tagOptions() {
      let res = [];

      // console.log('====================================');
      // console.log("this.addictionsData", this.addictionsData["ai-tag"]);
      // console.log('====================================');

      for (let item of this.addictionsData["ai-tag"]) {
        res.push({ value: item.id, label: item.name_en });
      }

      return res;
    },
  },

  created() {
    // for (let lang of this.languages) {
    //   this.form[`name_${lang}`] = null;
    // }

    for (let lang of this.languages) {
      this.form[`description_${lang}`] = null;
      this.form[`name_${lang}`] = null;
      this.form[`help_${lang}`] = null;
    }
  },

  methods: {
    // handleChangeFile({ value, key, type = "file" }) {
    //   console.log("handleChangeFile", this.form);

    //   this.form[key] = {
    //     type: "upload",
    //     file: value || null,
    //     url: type == "file" ? URL.createObjectURL(value) : value
    //   };

    //   // this.form[key].image = URL.createObjectURL(file);

    //   this.$emit("change", this.form);
    // },

    // deleteFile(key) {
    //   console.log("key", key);

    //   this.handlerDeleteFile(key);
    // },

    // deletePreset(index) {
    //   console.log(index);

    //   if (this.form.presets[index] && this.form.presets[index].id)
    //     this.handlerDeletePreset({
    //       id: this.form.presets[index].id,
    //       cb: () => {
    //         this.form.presets.splice(index, 1);
    //       }
    //     });
    //   else this.form.presets.splice(index, 1);
    // },

    // handleDeleteFife(data) {
    //   console.log("data", data);
    // }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
